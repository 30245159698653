<template>
  <div class="stats-card" :class="{'stats-card--select': !in_array(range, ['Tomorrow', 'Today'])}">

    <div class="stats-card__heading">
      <slot>
        <router-link :to="{name: 'orders.arrivalsDepartures', query: mainQuery}" class="stats-card__title">
          {{ title }}
        </router-link>
      </slot>
    </div>

    <router-link :to="{name: 'orders.arrivalsDepartures', query: arrivalQuery}" class="stats-card__score  arrivals">
      <span class="stats-card__icon-area  stats-card__icon-area--arrival">
        <i class="stats-card__icon  material-icons">flight_takeoff</i>
      </span>
      <div class="stats-card__score-content">
        <div class="stats-card__value">{{ arrivals }}</div>
<!--        <div class="stats-card__value">{{ arrivals }} <small>/ {{ spots }}</small></div>-->
        <div class="stats-card__type">{{ $t('arrivals_departures_table.types.arrivals') }}</div>
      </div>
    </router-link>

    <router-link :to="{name: 'orders.arrivalsDepartures', query: departureQuery}" class="stats-card__score  departures">
      <span class="stats-card__icon-area  stats-card__icon-area--departure">
        <i class="stats-card__icon  material-icons">flight_land</i>
      </span>
      <div class="stats-card__score-content">
        <div class="stats-card__value">{{ departures }}</div>
        <div class="stats-card__type">{{ $t('arrivals_departures_table.types.departures') }}</div>
      </div>
    </router-link>

  </div>
</template>

<script>
export default {
  name: "StatsCard",
  props: {

    title: {
      type: String,
    },

    range: {
      type: String,
    },

    arrivals: {
      type: Number,
      required: true,
    },

    // spots: {
    //   type: Number,
    //   required: true,
    // },

    departures: {
      type: Number,
      required: true,
    },

    additionalQueries: {
      type: Object,
    }
  },

  computed: {

    mainQuery: function () {

      let query = { range: this.range};
      if(this.additionalQueries && this.range === 'pickers') query = {...query, ...this.additionalQueries}

      return query
    },

    arrivalQuery: function () {

      let query = { range: this.range, type: 'arrivals' };
      if(this.additionalQueries && this.range === 'pickers') query = {...query, ...this.additionalQueries}

      return query
    },

    departureQuery: function () {

      let query = { range: this.range, type: 'departures' };
      if(this.additionalQueries && this.range === 'pickers') query = {...query, ...this.additionalQueries}

      return query
    }
  }
}
</script>