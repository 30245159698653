<template>
  <section>

    <app-loading v-if="!orders_loaded" />

    <d-row align-h="between" align-v="center" :no-gutters="true">
      <d-col class="table__header" :sm="12" :md="5">
       

        <template v-if="in_array(rangeFilter, allowRangeFilters)">
          <div v-if="rangeFilter !== 'pickers'" class="app__title">{{ $t('arrivals_departures_table.ranges.' + rangeFilter) }}</div>
          <div v-else class="app__title" style="font-size: 1.3rem; margin-top: 5px;">{{dateFormat(this.startDate)}} - {{dateFormat(this.endDate)}}</div>
        </template>
        <div class="app__subtitle mt-1">{{ $t("arrivals_departures_table.title") }}</div>
      </d-col>

      <d-col class="table__filter  table__filter--type" :sm="6" :md="4">

        <span class="table__filter-label  u-text-right">{{ $t("arrivals_departures_table.show") }}:</span>
        <div class="table__types">
          <template v-for="(typeOption, index) in typeOptions">
            <router-link class="table__type-button" :key="index" :to="{name: 'orders.arrivalsDepartures', query: {...$route.query, type: typeOption.value }}" :class="{ 'is-active': type === typeOption.value }">
              <span v-if="typeOption.value !== 'all'" class="table__type-icon">
                <i v-if="typeOption.value !== 'arrivals'" class="material-icons">flight_land</i>
                <i v-if="typeOption.value !== 'departures'" class="material-icons">flight_takeoff</i>
              </span>
              <span class="table__type-label">{{ typeOption.text }}</span>
            </router-link>
          </template>
        </div>

      </d-col>

    </d-row>

    <div class="stats-card__overview  sp-mtb-3">
      <d-card :class="{'is-active': rangeFilter === 'Today'}">
        <stats-card :title="$t('arrivals_departures_table.ranges.Today')"
                    range="Today"
                    :arrivals="ordersToday.arrivals.count"
                    :departures="ordersToday.departures.count" />
      </d-card>

      <d-card :class="{'is-active': rangeFilter === 'Tomorrow'}">
        <stats-card :title="$t('arrivals_departures_table.ranges.Tomorrow')"
                    range="Tomorrow"
                    :arrivals="ordersTomorrow.arrivals.count"
                    :departures="ordersTomorrow.departures.count" />
      </d-card>

      <d-card :class="{'is-active': !in_array(rangeFilter, ['Tomorrow', 'Today'])}">
        <stats-card :range="rangePicker"
                    :arrivals="ordersOthers.arrivals.count"
                    :departures="ordersOthers.departures.count"
                    :additionalQueries="{ startDate: dateFormatQuery(startDate), endDate: dateFormatQuery(endDate) }"
        >
          <d-form-select v-model="rangePicker" :options="rangeOptions" />

          <div class="stats-card__pickers" v-if="rangePicker === 'pickers'">
            <date-picker v-model="startDate"></date-picker>
            <date-picker v-model="endDate"></date-picker>
          </div>
        </stats-card>
      </d-card>

    </div>

    <d-row class="sp-mb-3" v-if="this.orders.length > 0">
      <d-col>
        <strong class="u-fs-14  u-color-secondary--800">{{ $t('arrivals_departures_table.summary_label')}}:</strong>
        <span class="u-fs-20  sp-ml-2">{{ priceFormat(ordersSummary,provider.currency) }}</span>
      </d-col>
    </d-row>

    <template v-if="this.orders.length > 0">
      <table class="table  table-hover  table__main  sp-mt-3">
        <thead class="table__thead">
        <tr>
          <th scope="col" style="width: 140px;" class="border-0"><span class="sortable">{{ $t("order_table.column_title.reference") }}</span></th>
          <th scope="col" style="width: 110px;" class="border-0"><span class="sortable">{{ $t("order_table.column_title.date") }}</span></th>
          <th scope="col" style="width: 80px;" class="border-0"><span class="sortable">{{ $t("order_table.column_title.time") }}</span></th>
          <th scope="col" style="width: 26px; padding: 0;" class="border-0"></th>
          <th scope="col" style="width: 90px;" class="border-0  u-text-center"><span class="sortable">{{ $t("order_table.column_title.travelers") }}</span></th>
          <th scope="col" class="border-0"><span class="sortable">{{ $t("order_table.column_title.name") }}</span></th>
          <th scope="col" class="border-0"><span class="sortable">{{ $t("order_table.column_title.email") }}</span></th>
          <th scope="col" style="width: 110px;" class="border-0"><span class="sortable">{{ $t("order_table.column_title.flight_number") }}</span></th>
          <th scope="col" style="width: 110px;" class="border-0"><span class="sortable">{{ $t("order_table.column_title.license") }}</span></th>
          <th scope="col" style="width: 240px;" class="border-0"><span class="sortable">{{ $t("order_table.column_title.car") }}</span></th>
          <th scope="col" style="width: 105px;" class="border-0"><span class="sortable">{{ $t("order_table.column_title.price") }}</span></th>
          <th scope="col" style="width: 105px;" class="border-0"><span class="sortable">{{ $t('form_fields.country') }}</span></th>
          <th scope="col" style="width: 115px; padding-bottom: 6px;" class="border-0  u-text-center">
         
          </th>
        </tr>
        </thead>

        <tbody>
        <template v-for="order in this.orders">
          <tr :key="order.id" @click="onSelectOrder(order, $event)">
            <td style="vertical-align: middle;">
              {{order.reference}}
            </td>

            <td style="vertical-align: middle;">{{dateFormat((type === 'arrivals' ? order.from : order.to))}}</td>
            <td style="vertical-align: middle;">{{ timeFormat((type === 'arrivals' ? order.from : order.to) ) }}</td>

            <td class="table__direction-column" style="vertical-align: middle;">
              <span class="table__direction-icon" :class="type">
                <i v-if="type === 'arrivals'" class="material-icons">flight_takeoff</i>
                <i v-else class="material-icons">flight_land</i>
              </span>
            </td>

            <td style="vertical-align: middle;" class="u-text-center">
              {{ order.journey.travellers }}
            </td>
            <td style="vertical-align: middle;">
              {{order.lastName}}, {{order.firstName}}<br>
            </td>
            <td style="vertical-align: middle;">
              {{order.email}}
            </td>
            <td style="vertical-align: middle;">
              {{ type === 'arrivals' ? order.journey.departure_flight_number : order.journey.arrival_flight_number }}
            </td>
            <td style="vertical-align: middle;">
              {{order.journey.car.license_plate}} 
            
            </td>
            <td style="vertical-align: middle;">
              {{ order.journey.car.model }} - {{ order.journey.car.color }}
            </td>
            <td style="vertical-align: middle;">{{ priceFormat(order.price.amount,order.price.currency)}}</td>
            <td style="vertical-align: middle;">  {{ order.language.toUpperCase() }} {{ order.price.currency }}  </td> 
            <td  style="vertical-align: middle;" data-column="voucher" class="u-text-center">
             <a class="table__voucher" v-if="isset(order.voucher) && !empty(order.voucher.url)" :href="order.voucher.url"><i class="material-icons mr-2" style="font-size: 18px;">sim_card_download</i></a>
           <span v-else >&#45;&#45;</span>
            </td>
          </tr>
        </template>
        </tbody>
      </table>

      <pagination
        v-on:goToPage="goToPage($event)"
        v-on:setPerPage="setPerPage($event)"
        v-if="ordersCount > 0"
        ref="pagination"
        :amountOfItems="ordersCount"
        :totalPages="pagesCount"
        :currentPage="currentPage"
        :perPage="perPage" >
      </pagination>
    </template>
    <div v-else class="table__no-orders">
      <template v-if="in_array(rangeFilter, allowRangeFilters)">
        {{ $t('arrivals_departures_table.no_orders', [ type === 'arrivals' ? this.$t('arrivals_departures_table.types.arrivals') : this.$t('arrivals_departures_table.types.departures'), rangeFilter !== 'pickers' ? $t('arrivals_departures_table.ranges.' + rangeFilter) : dateFormat(this.startDate) + ' - ' + dateFormat(this.endDate)]) }}
      </template>
    </div>


    <d-modal v-if="downloadInvoicesPopUp" @close="downloadInvoicesPopUp = false">
      <d-modal-header>
        <d-modal-title>{{ $t('arrivals_departures_table.download_invoices.pop_up_title')  }}</d-modal-title>
      </d-modal-header>
      <d-modal-body>
        <template v-if="generatingVoucherUrl">
          <div class="u-flex-center">
            <loading />
          </div>
          <p class="sp-mt-3">{{ $t('arrivals_departures_table.download_invoices.loading_text')}}</p>
        </template>
        <template v-else>
          <p>{{ $t('arrivals_departures_table.download_invoices.download_ready')}}</p>
          <a v-bind:href="downloadInvoicesUrl" target="_blank" class="btn btn-primary">
            {{ $t('arrivals_departures_table.download_invoices.download_invoices')}}
          </a>
        </template>
      </d-modal-body>
    </d-modal>

  </section>
</template>

<script>
import {mapProviderStateFields} from "~/store";
import graphqlCalls from "~/graphql";
import mixins from "~/mixins";
import StatsCard from "~/components/StatsCard";
import {providerOrderSettings} from "~/graphql/providers/GetProviderOrders";

import Pagination from "~/components/Pagination";
import AppLoading from "@/components/AppLoading";
import DatePicker from "@/components/DatePicker";
import Loading from "../components/loading";


export default {
  name: "ArrivalsDepartures",
  components: {Loading, DatePicker, AppLoading, Pagination, StatsCard},
  mixins: [graphqlCalls, mixins],

  data() {
    return {
      orders: [],
      orders_loaded: false,

      downloadInvoicesPopUp: false,
      generatingVoucherUrl: false,
      downloadInvoicesUrl: '',

      ordersToday: {
        arrivals: {
          count: 0,
          summary: 0,
        },
        departures: {
          count: 0,
          summary: 0,
        },
      },

      ordersTomorrow: {
        arrivals: {
          count: 0,
          summary: 0,
        },
        departures: {
          count: 0,
          summary: 0,
        },
      },

      ordersOthers: {
        arrivals: {
          count: 0,
          summary: 0,
        },
        departures: {
          count: 0,
          summary: 0,
        },
      },

      allowRangeFilters: [
        'Today',
        'Tomorrow',
        'ThisWeek',
        'NextWeek',
        'ThisMonth',
        'NextMonth',
        'pickers',
      ],

      rangeFilter: 'Today',
      rangePicker: 'ThisMonth',

      rangeOptions: [

        {
          text: this.$t('arrivals_departures_table.ranges.ThisMonth'),
          value: "ThisMonth"
        },
        {
          text: this.$t('arrivals_departures_table.ranges.NextMonth'),
          value: "NextMonth"
        },
        {
          text: this.$t('arrivals_departures_table.pick_dates'),
          value: "pickers"
        }
      ],

      startDate: new Date(),
      endDate: new Date(new Date().getTime()+( 7 * 24*60*60*1000)),

      searchValue: "",
      typeOptions: [
        // {
        //   text: this.$t('arrivals_departures_table.types.all'),
        //   value: 'all'
        // },
        {
          text: this.$t('arrivals_departures_table.types.arrivals'),
          value: 'arrivals'
        },
        {
          text: this.$t('arrivals_departures_table.types.departures'),
          value: 'departures'
        },
      ],

      ordersCount: 0,
      pagesCount: 1,
      currentPage: 1,
      perPage: 20,
    };
  },

  computed: {
    ...mapProviderStateFields([
      'active_provider_index',
      'providers',
    ]),

    type: function () {
      if(this.$route.query.type === undefined) return 'arrivals'
      else return this.$route.query.type
    },

    provider : function () {
      if(this.providers.length === 0) return null
      return this.providers[this.active_provider_index];
    },

    ordersSummary: function () {

      if(this.rangeFilter === 'Today') return this.ordersToday[this.type].summary
      if(this.rangeFilter === 'Tomorrow') return this.ordersTomorrow[this.type].summary
      return this.ordersOthers[this.type].summary
    },
  },

  created() {

    if(this.$route.query.perPage !== undefined) this.perPage = parseInt(this.$route.query.perPage);
    if(this.$route.query.page !== undefined) this.currentPage = parseInt(this.$route.query.page);

    if(this.$route.query.startDate !== undefined) {
      const startDateObject = this.makeDayjsDate(this.$route.query.startDate)
      this.startDate = startDateObject.toDate()
    }

    if(this.$route.query.endDate !== undefined) {
      const endDateObject = this.makeDayjsDate(this.$route.query.endDate)
      this.endDate = endDateObject.toDate()
    }

    if(this.$route.query.range !== undefined) {
      this.rangeFilter = this.$route.query.range;
      if(!this.in_array(this.rangeFilter, ['Tomorrow', 'Today'])) this.rangePicker = this.rangeFilter
    }
  },

  mounted() {

    if(!this.in_array(this.rangeFilter, this.allowRangeFilters)) this.rangeFilter = 'ThisWeek'

    if(this.provider) {
      this.loadServiceLocations(this.provider.id)
      //this.loadOrders(this.provider.id)
     
      //this.countOrdersForStats(this.provider.id)
      //this.countOrdersForOtherStats(this.provider.id)
    }
  },

  methods: {

    calculateSummary: function (orders) {

      let total = 0;

      orders.forEach((order) => {

        if(order.mobianID == 0 || order.mobianID == null ) total += parseFloat(order.price.amount);
      })

      return total
    },
    loadServiceLocations: async function (providerId) {
    
    let serviceLocationsIds = []
    await this.fetchServiceLocations(providerId,this.date).then((response) => {
        response.data.provider.serviceLocations.forEach((serviceLocation)=>{
          serviceLocationsIds.push(serviceLocation.id)
          
        })
  })

  if(serviceLocationsIds.length > 0) {
    this.loadOrders(providerId,serviceLocationsIds)
    this.countOrdersForStats(serviceLocationsIds)
    this.countOrdersForOtherStats(serviceLocationsIds)
  }
},
    loadOrders: function (providerId,serviceLocationsIds) {
      if(!this.in_array(this.rangeFilter, this.allowRangeFilters)) this.rangeFilter = 'ThisWeek'
      const self = this;
      this.orders_loaded = false
      const settings = this.deepClone(providerOrderSettings);
      settings.filters.status = 'COMPLETED'
      settings.filters.period.direction = this.type === 'arrivals' ? 'Arrival' : 'Departure';
      settings.sorting.sortBy = this.type === 'arrivals' ? 'from' : 'to';
      settings.filters.service_locations_ids = serviceLocationsIds
      if(this.rangeFilter !== 'pickers') {
        settings.filters.period.type = this.rangeFilter;
      }
      else {
        settings.filters.period.from = this.startDate
        settings.filters.period.to = this.endDate
      }

      settings.pagination.limit = this.perPage;
      settings.pagination.page = this.currentPage;

      this.fetchOrders(settings).then((response) => {
        
        self.orders = response.data.orders ?? []
       
        self.orders_loaded = true
      
      }),
      
      this.fetchOrdersCount  ( settings).then((response) => {
        self.ordersCount = response.data.ordersCount
        self.pagesCount = Math.ceil(response.data.ordersCount/this.perPage)
    
      })
    },
 
    countOrdersForStats: function (serviceLocationsIds) {

      if(!this.in_array(this.rangeFilter, this.allowRangeFilters)) this.rangeFilter = 'ThisWeek'

      const self = this;
      this.countOrdersTodayTomorrow(serviceLocationsIds).then((response) => {
     
        self.ordersToday.arrivals.count = response.data.arrivalsToday.length
        self.ordersToday.departures.count = response.data.departuresToday.length

        self.ordersToday.arrivals.summary = (self.ordersToday.arrivals.count === 0) ? 0 : this.calculateSummary(response.data.arrivalsToday)
        self.ordersToday.departures.summary = (self.ordersToday.departures.count === 0) ? 0 : this.calculateSummary(response.data.departuresToday)

        self.ordersTomorrow.arrivals.count = response.data.arrivalsTomorrow.length
        self.ordersTomorrow.departures.count = response.data.departuresTomorrow.length

        self.ordersTomorrow.arrivals.summary = (self.ordersTomorrow.arrivals.count === 0) ? 0 : this.calculateSummary(response.data.arrivalsTomorrow)
        self.ordersTomorrow.departures.summary = (self.ordersTomorrow.departures.count === 0) ? 0 : this.calculateSummary(response.data.departuresTomorrow)
      })
    },

    countOrdersForOtherStats: function (serviceLocationsIds) {


      if(!this.in_array(this.rangeFilter, this.allowRangeFilters)) this.rangeFilter = 'ThisWeek'
      
      const self = this;
      if(this.rangeFilter !== 'pickers') this.countOrdersForPeriodType(serviceLocationsIds, this.rangePicker).then((response) => {

        self.ordersOthers.arrivals.count = response.data.arrivals.length
        self.ordersOthers.departures.count = response.data.departures.length

        self.ordersOthers.arrivals.summary = (self.ordersOthers.arrivals.count === 0) ? 0 : this.calculateSummary(response.data.arrivals)
        self.ordersOthers.departures.summary = (self.ordersOthers.departures.count === 0) ? 0 : this.calculateSummary(response.data.departures)
      });
      else this.countOrderForPeriodRange(serviceLocationsIds, this.startDate, this.endDate).then((response) => {
      

        self.ordersOthers.arrivals.count = response.data.arrivals.length
        self.ordersOthers.departures.count = response.data.departures.length

        self.ordersOthers.arrivals.summary = (self.ordersOthers.arrivals.count === 0) ? 0 : this.calculateSummary(response.data.arrivals)
        self.ordersOthers.departures.summary = (self.ordersOthers.departures.count === 0) ? 0 : this.calculateSummary(response.data.departures)
      });
    },

    downloadInvoices() {

      if(!this.provider) return

      if(!this.in_array(this.rangeFilter, this.allowRangeFilters)) this.rangeFilter = 'ThisWeek'

      this.downloadInvoicesPopUp = true;
      this.generatingVoucherUrl = true;

      const settings = this.deepClone(providerOrderSettings);
      settings.filters.status = 'COMPLETED'
      settings.filters.period.direction = this.type === 'arrivals' ? 'Arrival' : 'Departure';

      if(this.rangeFilter !== 'pickers') {
        settings.filters.period.type = this.rangeFilter;
      }
      else {
        settings.filters.period.from = this.startDate
        settings.filters.period.to = this.endDate
      }

      this.generateVouchersDownload(this.provider.id, settings).then((response) => {

        this.downloadInvoicesUrl = response.data.provider.vouchers
        this.generatingVoucherUrl = false
      })

    },

    goToPage: function (page) {
      this.currentPage = page;

      const query = {...this.$route.query}
      query.page = page
      this.$router.replace({path: this.$route.path, query: query});
      this.loadServiceLocations(this.provider.id)
      //this.loadOrders(this.provider.id)
    },

    setPerPage: function (perPageValue) {
      this.perPage = perPageValue;

      const query = {...this.$route.query}
      query.perPage = perPageValue

      // Remove page prop, and reset currentPage to 1
      delete query.page
      this.currentPage = 1

      this.$router.replace({path: this.$route.path, query: query});
      this.loadServiceLocations(this.provider.id)
      //this.loadOrders(this.provider.id)
    },

    onSelectOrder(order, event) {

      const td = event.target.closest('td')
      const tdColumn = (td !== null) ? td.dataset.column : undefined

      // Return when click on voucher column
      if(td && tdColumn && tdColumn === 'voucher') return

      this.$router.push({name: 'orders.show', params: {id: order.id}})
    }
  },

  watch: {
    '$route.query.range': function (range) {
      this.rangeFilter = range;
      if(this.provider) {
        this.currentPage = 1
        this.loadServiceLocations(this.provider.id);
        //this.loadOrders(this.provider.id)

        // if(!this.in_array(range, ['Tomorrow', 'Today'])) {
        //   this.countOrdersForOtherStats(this.provider.id)
        // }
      }
    },
    startDate: function () {

      const queryParams = {...this.$route.query }
      queryParams.range = 'pickers'
      queryParams.startDate = this.dateFormatQuery(this.startDate)
      queryParams.endDate = this.dateFormatQuery(this.endDate)

      if(this.in_array(this.rangeFilter, ['Tomorrow', 'Today']) || !this.equalObjects(this.$route.query, queryParams)) {
        this.$router.push({name: 'orders.arrivalsDepartures', query: queryParams});
      }

      if(this.startDate >= this.endDate) {
        this.endDate = new Date(this.startDate.getTime()+(24*60*60*1000))
      }
      if(this.provider) {
        this.currentPage = 1
        this.loadServiceLocations(this.provider.id)
        // this.loadOrders(this.provider.id)  
        // this.countOrdersForOtherStats(this.provider.id)
      }
    },
    endDate: function () {

      const queryParams = {...this.$route.query }
      queryParams.range = 'pickers'
      queryParams.startDate = this.dateFormatQuery(this.startDate)
      queryParams.endDate = this.dateFormatQuery(this.endDate)

      if(this.in_array(this.rangeFilter, ['Tomorrow', 'Today']) || !this.equalObjects(this.$route.query, queryParams)) {
        this.$router.push({name: 'orders.arrivalsDepartures', query: queryParams});
      }

      if(this.endDate < this.startDate) {
        this.startDate = new Date(this.endDate.getTime()-(24*60*60*1000))
      }
      if(this.provider) {
        this.currentPage = 1
        this.loadServiceLocations(this.provider.id)
       // this.loadOrders(this.provider.id)
        //this.countOrdersForOtherStats(this.provider.id)
      }
    },
    type: function () {
      if(this.provider) {
        this.currentPage = 1
        this.loadServiceLocations(this.provider.id);
        //this.loadOrders(this.provider.id)
      }
    },
    rangePicker: function () {

      const queryParams = {...this.$route.query, range: this.rangePicker }

      if(this.rangePicker === 'pickers') {
        queryParams.startDate = this.dateFormatQuery(this.startDate)
        queryParams.endDate = this.dateFormatQuery(this.endDate)
      }
      else {
        delete queryParams.startDate;
        delete queryParams.endDate;
      }

      this.$router.push({name: 'orders.arrivalsDepartures', query: queryParams});
    },
    provider: function (val) {
      if(!val) return;
      this.loadServiceLocations(val.id);
      //this.loadOrders(val.id);
      //this.countOrdersForStats(val.id)
      //this.countOrdersForOtherStats(val.id)
    },
  },
};
</script>
